import React from 'react'
import "./buttonLang.css";

const ButtonLang = ({ lang, handleLanguageChange }) => {
  return (
    <div className='toggle-container' onClick={handleLanguageChange}>
      <div className={`toggle-btn ${!lang ? "disable" : ""}`}>
        {lang ? "ID" : "EN"}
      </div>
    </div>
  )
}

export default ButtonLang