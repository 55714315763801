import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navbar/Navigation";
import "./restaurants.css";
import img1 from "../../assets/card_background_montain/background5.png";
import img2 from "../../assets/card_background_montain/img-restaurant2.png";
import img3 from "../../assets/card_background_montain/imh-restaurant3.png";
import BackgroundImgMontain from "../../components/BackgroundImgMontain/BackgroundImgMontain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../components/Footer/Footer";
import Carousel from "react-multi-carousel";
import { getRestaurant } from "../../services/restaurant";
import { Link } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { BsArrowRightShort } from "react-icons/bs";
import Contact from "../../components/ContactComponent/ContactComponent";
import TiketComponent from "../../components/TiketComponent/TiketComponent";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Restaurants() {
  const [restaurants, setRestaurants] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const restaurants = await getRestaurant();
      // console.log('restaurants');
      // console.log(restaurants);

      setRestaurants(restaurants.data.data.restaurants);
    };
    fetchData();
  }, []);

  // console.log('data state restaurants');
  // console.log(restaurants);
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg">
        <section className="text-center">
          <h1 className="title_page">Restaurant</h1>
          <TiketComponent />
          <Contact />
          <div className="container">
            <Carousel responsive={responsive}>
              {restaurants.map((resto) => (
                <div key={resto.id_restos} className="attraction">
                  <Link to={`/restaurants/${resto.show.slug}`}>
                    <div className="att-image">
                      <img src={resto.image[0].image_path} alt="Image Title" />
                      <div className="overlayInfo">
                        <h3>{resto.show.title}</h3>

                        {/* <BsArrowRightShort className="icon" /> */}
                      </div>
                    </div>
                  </Link>
                  <div className="destFooter">
                    <div className="destText flex">
                      <Link to={`/restaurants/${resto.show.slug}`}>
                        <h6>{resto.show.title}</h6>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Restaurants;
