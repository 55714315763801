import React, { useState, useEffect } from "react";
import "./mapsDetail.css";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import { useParams } from "react-router-dom";
import { getMapsDetail } from "../../services/mapsService";

const MapsDetail = () => {
  const { slug } = useParams();
  const [mapsDetail, setMapsDetail] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const mapsDetail = await getMapsDetail(slug);

      setMapsDetail(mapsDetail.data.data);
    };
    fetchData();
  }, []);

  const handleDownload = () => {
    // Check if the file path exists
    if (mapsDetail && mapsDetail.maps && mapsDetail.maps.file_path) {
      // Create a temporary anchor tag to trigger the download
      const link = document.createElement('a');
      link.href = mapsDetail.maps.file_path;
      // Set the download attribute to the filename you wish to download as
      // Extract the filename from the file_path or set a default name
      const fileName = mapsDetail.maps.file_path.split('/').pop() || 'download';
      link.setAttribute('download', fileName); // Or set a custom file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('File path is not available.');
    }
  };

  const handleGmaps = () => {
    // Check if the gmaps link exists
    if (mapsDetail && mapsDetail.maps && mapsDetail.maps.url) {
      // Open the gmaps link in a new tab
      window.open(mapsDetail.maps.url, '_blank');
    } else {
      console.error('Gmaps link is not available.');
    }
  }

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="blogDetailNews">
        <div className="newsHeader">
          <h1>{mapsDetail && mapsDetail.show.title}</h1>
          {/* <p>{news && news.news.created_at}</p> */}
        </div>
        <img
          className="image-news"
          src={mapsDetail && mapsDetail.show.image[0].image_path}
          alt=""
        />

        <div className="newsContent">
          <p>
            {mapsDetail &&
              mapsDetail.show.description.replace(/(<([^>]+)>)/gi, "")}
          </p>
        </div>
        <button onClick={handleDownload} style={{color:"white"}} className="btn">Download Maps</button>
        <button onClick={handleGmaps} style={{color:"white"}} className="btn">Gmaps Link</button>
      </div>
      <Footer />
    </>
  );
};

export default MapsDetail;
