import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import BankCheckbox from "../../components/Banks/Banks";
import { requestPayment } from "../../services/ticketService";
import { fetchingBookingSummary } from "../../services/ticketService";
import { AppContext } from "../../App";
import AuthService from "../../services/authService";
import peraturan from "../../assets/peraturan-min.webp";
import cico from "../../assets/ci-co-2.png";

const GlampingSummaryPayment = () => {
  const { slug, bookingNumber } = useParams();
  const navigate = useNavigate();
  let [summaryGlamping, setSummaryGlamping] = useState("");
  const { selectedBank, setTicketQr, setSelectedBank, method } =
    useContext(AppContext);
  // let method = "va";
  let bank = selectedBank;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSelectedBank(null);
        const summary = await fetchingBookingSummary(bookingNumber);
        setSummaryGlamping(summary);
      } catch (error) {
        if ((error.response && error.response.status === 401) || 403) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          window.location.href = "/login";
        }
      }
    };
    fetchData();
  }, [bookingNumber]);

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>Payment</h1>
        <div className="bg-booking-va">
          <div className="bg-white">
            <h5>Summary</h5>
            <div className="summary-bg">
              <span>
                Room:{" "}
                {summaryGlamping &&
                  summaryGlamping.data?.transaction?.order_data?.priceArr
                    ?.detail[0].room}{" "}
              </span>
              <span>
                Total Harga: Rp{" "}
                {summaryGlamping &&
                  summaryGlamping.data?.transaction?.order_data?.priceArr
                    ?.total}
                ,-
              </span>
              <span>
                Checkin:{" "}
                {summaryGlamping &&
                  summaryGlamping.data?.transaction?.show.checkin}
              </span>
              <span>
                Checkout:{" "}
                {summaryGlamping &&
                  summaryGlamping.data?.transaction?.show.checkout}
              </span>
            </div>

            <div className="summary-bg">
              <span>
                Name:{" "}
                {summaryGlamping &&
                  summaryGlamping.data?.transaction?.booker_name}
              </span>
              <span>
                Email:
                {summaryGlamping && summaryGlamping.data?.transaction?.email}
              </span>
              <span>
                Phone:{" "}
                {summaryGlamping && summaryGlamping.data?.transaction?.phone}
              </span>
            </div>

            <br />
            <span className="protokol">
              <h5>Ketentuan Pengunjung</h5>
              <img src={cico} alt="" />
              <img src={peraturan} alt="" />
              {/* <ul>
                <li>
                • ⁠Dilarang membawa senjata tajam / Narkoba atau obat-obatan
                  terlarang
                </li>
                <li>• Dilarang membawa hewan peliharaan</li>
                <li>
                • ⁠Dilarang membawa benda yang mempunyai bau menyegat kedalam
                  kamar
                </li>
                <li>• ⁠Dilarang mencoret dan merusak fasilitas</li>
                <li>• ⁠Dilarang mengganggu pengunjung lain</li>
                <li>• ⁠Dilarang memasak didalam kamar</li>
                <li>• ⁠Dilarang menginap untuk pasangan yang bukan muhrim</li>
                <li>• ⁠Dilarang merokok didalam kamar</li>
                <li>• ⁠Jam 14.00 WITA Check in</li>
                <li>• ⁠Jam 12.00 WITA Check Out</li>
              </ul> */}
            </span>

            <span className="info-va">
              Pembayaran dapat dilakukan dengan metode pembayaran berikut.
            </span>
            <div className="banks-va">
              <BankCheckbox />
            </div>

            <button
              className="btn btn-book"
              onClick={() =>
                requestPayment(
                  bookingNumber,
                  method,
                  bank,
                  navigate,
                  slug,
                  setTicketQr
                )
              }
            >
              Bayar
            </button>
            <span className="need-info">
              <a href="#">Need more information?</a>{" "}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GlampingSummaryPayment;
