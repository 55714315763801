// src/components/BankCheckbox.js
import React, { useState, useEffect, useContext } from "react";
import "./BankCheckbox.css";
import imgBca from "../../assets/bca-logo.png";
import imgMandiri from "../../assets/mandiri.png";
import { AppContext } from "../../App";
import { fetchingBookingSummary } from "../../services/ticketService";
import { useParams } from "react-router-dom";
import qrisimg from "../../assets/qris.png";

const BankCheckbox = () => {
  const { slug, bookingNumber } = useParams();
  const { setSelectedBank, selectedBank, setMethod } = useContext(AppContext);
  const [banks, setBanks] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  // const [selectedBank, setSelectedBank] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const bankData = await fetchingBookingSummary(bookingNumber);
      fetchingBookingSummary(bankData);
      setBanks(bankData.data?.banks);
    };
    fetchData();
  }, [bookingNumber]);

  const handleCheckboxChange = (slug) => {
    setSelectedBank(slug);
    setSelectedOption(slug);
    setMethod("va");
    // setQris(null);
  };

  const handleCheckboxChangeQris = () => {
    setSelectedBank(null);
    setSelectedBank("qris");
    setSelectedOption("qris");
    setMethod("qris");
    // setQris("qris");
  };

  return (
    <div className="payment-method">
      {/* VA Title */}
      <span className="method-title">Virtual Account</span>
      {/* VA Title */}
      <div className="bank-checkbox-container">
        {banks &&
          banks.map((bank) => (
            <label key={bank.slug} className="bank-checkbox">
              <input
                type="radio"
                name="bank"
                id={bank.slug}
                checked={selectedBank === bank.slug}
                onChange={() => handleCheckboxChange(bank.slug)}
              />
              <img src={bank.icon} alt={bank.title} className="bank-logo" />
            </label>
          ))}
      </div>
      <span className="method-title">QRIS</span>
      <label className="qris-checkbox">
        <input
          type="radio"
          name="qris"
          id="qris"
          checked={selectedOption === "qris"}
          onChange={() => handleCheckboxChangeQris()}
        />
        <img src={qrisimg} alt="qris" className="bank-logo" />
      </label>
    </div>
  );
};

export default BankCheckbox;
