import React, { useState, useEffect, memo, useContext } from "react";
import "./Navigation.css";
// import { SiYourtraveldottv } from "react-icons/si";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import kalselLogo from "../../assets/kalselpark-logo-new-min.png";
import loksadoLogo from "../../assets/logo-loksado1.png";
import mountainviewLogo from "../../assets/logo-mountainview1.png";
import pasarapungLogo from "../../assets/logo-pasarapung1.png";
import bukitLogo from "../../assets/logo-bukit1.png";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import Dropdown from "react-bootstrap/Dropdown";
import Information from "../../assets/icon-navbar/about.png";
import Event from "../../assets/icon-navbar/event.png";
import Maps from "../../assets/icon-navbar/maps.png";
import Package from "../../assets/icon-navbar/package.png";
import Resto from "../../assets/icon-navbar/resto.png";
import Shop from "../../assets/icon-navbar/shop.png";
import Stay from "../../assets/icon-navbar/stay.png";
import Ticket from "../../assets/icon-navbar/ticket-icon.png";
import Wahana from "../../assets/icon-navbar/wahana_white.png";
import { useSelector, useDispatch } from "react-redux";
import { setIsLoggedIn, setLogout } from "../../features/login/loginSlice";
import { Link } from "react-router-dom";
import ButtonLang from "../ButtonLang/ButtonLang";
import { AppContext } from "../../App";

const Navigation = () => {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const [modalComingSoon, setModalComingSoon] = useState(false);
  const { language, setLanguage } = useContext(AppContext);
  const handleLogin = () => {
    dispatch(setIsLoggedIn(true));
  };

  const handleLanguageChange = () => {
    setLanguage(!language);
  };
  // console.log("language", language);

  // const handleLogout = () => {
  //   dispatch(setLogout());
  // };

  const [isMenuOpen, setMenuOpen] = useState(true);
  const navigate = useNavigate();
  const [active, setActive] = useState("navBar");
  const [transparent, setTransparent] = useState("header");

  // const toggleMenu = () => {
  //   setMenuOpen(!isMenuOpen);
  // };

  const token = localStorage.getItem("user");
  useEffect(() => {
    // Check if the user is logged in by checking the presence of a token
    // const token = localStorage.getItem("user");
    if (token) {
      handleLogin(true);
    }
  }, []);

  const handleShown = () => {
    setIsShown(!isShown);
  };

  const handleModal = () => {
    setModalComingSoon(!modalComingSoon);
    removeNavbar();
    console.log("modalComingSoon");
    console.log(modalComingSoon);
  };

  const showNav = () => {
    setActive("navBar activeNavbar");
  };

  const addBg = () => {
    if (window.scrollY >= 10) {
      setTransparent("header activeHeader");
    } else {
      setTransparent("header");
    }
  };
  window.addEventListener("scroll", addBg);

  const removeNavbar = () => {
    setActive("navBar");
  };

  return (
    <section className="navBarSection">
      {modalComingSoon && (
        // <h2>You need to login to book ticket</h2>
        <div className="modals1">
          <div className="modal-content">
            <h2>Coming Soon</h2>
            <span className="close" onClick={handleModal}>
              Close
            </span>
          </div>
        </div>
      )}

      <div className={transparent}>
        <div className="logoDiv">
          <a className="logo">
            <h1 className="flex">
              <div className="col-md-7">
                <img
                  src={kalselLogo}
                  alt=""
                  className="iconLogo"
                  onClick={() => navigate("/")}
                />
              </div>
            </h1>
          </a>

          {/* <div className="subLogo">
         
            <Link to="/kalselpark-mountainview">
              <div>
                <h1 className="flex">
                  <img src={mountainviewLogo} alt="" className="iconSubLogo" />
                </h1>
              </div>
            </Link>

            <div>

              <a className="logo" onClick={() => navigate("/coming-soon")}>
                <h1 className="flex">

                  <img src={pasarapungLogo} alt="" className="iconSubLogo" />
                </h1>
              </a>
            </div>

            <div>

              <a className="logo" onClick={() => navigate("/coming-soon")}>
                <h1 className="flex">

                  <img src={loksadoLogo} alt="" className="iconSubLogo" />
                </h1>
              </a>
            </div>

            <div>

              <a className="logo" onClick={() => navigate("/coming-soon")}>
                <h1 className="flex">

                  <img src={bukitLogo} alt="" className="iconSubLogo" />
                </h1>
              </a>
            </div>
          </div> */}
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li className="navItem logoHide">
              <Link className="navLink" to="/events">
                {/* <img src={mountainviewLogo} alt="" className="iconSubLogo" /> */}
                Events
                {language ? "Event" : "Events"}
              </Link>
            </li>

            <li className="navItem logoHide">
              <Link className="navLink" to="/package">
                {/* <img src={mountainviewLogo} alt="" className="iconSubLogo" /> */}
                {language ? "Paket" : "Package"}
              </Link>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <Link className="navLink" to="/ticket-attraction">
                {/* <img src={pasarapungLogo} alt="" className="iconSubLogo" /> */}
                {language ? "Tiket" : "Tickets"}
              </Link>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <Link className="navLink" to="/restaurants">
                {/* <img src={loksadoLogo} alt="" className="iconSubLogo" /> */}
                Restaurants
              </Link>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <Link className="navLink" to="/resort">
                {/* <img src={bukitLogo} alt="" className="iconSubLogo" /> */}
                Resort
              </Link>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <Link className="navLink" to="/maps">
                {/* <img src={bukitLogo} alt="" className="iconSubLogo" /> */}
                Maps
              </Link>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <Link className="navLink" to="/merchandise">
                {/* <img src={bukitLogo} alt="" className="iconSubLogo" /> */}
                Shop
              </Link>
            </li>

            <div className="headerBtns flex">
              {isLoggedIn && token ? (
                <>
                  <button onClick={() => navigate("/profile")} className="btn">
                    <a>Profile</a>
                  </button>

                  <button onClick={AuthService.logout} className="btn">
                    <a>Logout</a>
                  </button>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    onClick={() => navigate("/login")}
                    className="btn loginBtn"
                  >
                    <a>Login</a>
                  </button>
                  <button onClick={() => navigate("/register")} className="btn">
                    <a>Register</a>
                  </button>
                </>
              )}
              <ButtonLang
                lang={language}
                handleLanguageChange={handleLanguageChange}
              />
            </div>
          </ul>

          <div onClick={removeNavbar} className="closeNavbar">
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon" />
        </div>
      </div>
      {/* {isShown ? ( */}
      <div className="is-shown">
        {/* <div className="icon-menu" onClick={() => navigate("/package")}> */}
        <div className="icon-menu">
          <Link to="/package">
            <img className="img-icon" src={Package} alt="" />
            <span className="icon-title">Package</span>
          </Link>
          {/* </div> */}
        </div>

        <div className="icon-menu">
          <Link to="/events">
            <img className="img-icon" src={Event} alt="" />
            <span className="icon-title">Event</span>
          </Link>
        </div>

        <div className="icon-menu">
          <Link to="/resort">
            <img className="img-icon" src={Stay} alt="" />
            <span className="icon-title">Resort</span>
          </Link>
        </div>

        <div className="icon-menu">
          <Link to="/merchandise">
            <img className="img-icon" src={Shop} alt="" />
            <span className="icon-title">Shops</span>
          </Link>
        </div>

        <div className="icon-menu">
          <Link to="/ticket-attraction">
            <img className="img-icon" src={Ticket} alt="" />
            <span className="icon-title">Tickets</span>
          </Link>
        </div>

        <div className="icon-menu">
          <Link to="/restaurants">
            <img className="img-icon" src={Resto} alt="" />
            <span className="icon-title">Restaurant</span>
          </Link>
        </div>

        <div
          className="icon-menu"
          onClick={() => navigate("/ticket-attraction")}
        >
          <img className="img-icon" src={Wahana} alt="" />
          <span className="icon-title">Attractions</span>
        </div>

        <div className="icon-menu">
          <Link to="/maps">
            <img className="img-icon" src={Maps} alt="" />
            <span className="icon-title">Maps</span>
          </Link>
        </div>

        <div className="icon-menu">
          <img className="img-icon" src={Information} alt="" />
          <span className="icon-title">About</span>
        </div>
      </div>
      {/* ) : null} */}
    </section>
  );
};

export default memo(Navigation);
