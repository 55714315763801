import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Ticket.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { BsArrowRightShort } from "react-icons/bs";
import Navigation from "../../components/Navbar/Navigation";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../components/Footer/Footer"; // Add the import statement for the Footer component
import { Link } from "react-router-dom";
import {
  fetchTickets,
  fetchStayAccomodation,
} from "../../services/ticketService";
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { resetTicketState } from "../../features/ticket/ticketSlice";
import { resetGlampingState } from "../../features/glamping/glampingSlice";
import Contact from "../../components/ContactComponent/ContactComponent";
import TiketComponent from "../../components/TiketComponent/TiketComponent";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Ticket = ({ attraction }) => {
  const dispatch = useDispatch();
  // const attractions = useSelector((state) => state.attraction.attractions);
  const navigate = useNavigate();
  const [attractions, setAttractions] = useState([]);
  const [accomodations, setAccomodations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const tickets = await fetchTickets();
      const accomodations = await fetchStayAccomodation();

      setAttractions(tickets.data.tickets);
      setAccomodations(accomodations.data.stays);
    };
    fetchData();
  }, []);

  //reset ticket state
  useEffect(() => {
    dispatch(resetTicketState());
    dispatch(resetGlampingState());
  }, [dispatch]);
  // console.log(attractions);

  return (
    <div className="bg-image-ticket">
      <Navigation />
      <div className="header"></div>
      <section className="ticket section container">
        <div className="secContainer">
          <div className="secIntro">
            <div className="secTitle">Tickets</div>
          </div>
        </div>
        <TiketComponent />
        <Contact />

        <div className="secIntroSub">
          <div className="secTitleSub">Main Attraction</div>
        </div>

        <Carousel responsive={responsive}>
          {attractions.map(
            ({
              id_ticket,
              image_path,
              ticket_name,
              slug,
              price,
              ticket_description,
            }) => {
              return (
                <div key={id_ticket} className="attraction">
                  <div className="att-image">
                    <Link to={`/ticket-attraction/${slug}`}>
                      <img src={image_path} alt="Image Title" />
                      <div className="overlayInfo">
                        <h3>{ticket_name}</h3>
                        <p>Rp {price}</p>

                        {/* <BsArrowRightShort className="icon" /> */}
                      </div>
                    </Link>
                  </div>
                  <div className="destFooter">
                    <div className="destText flex">
                      <Link to={`/ticket-attraction/${slug}`}>
                        <h6>{ticket_name}</h6>
                      </Link>
                      <p>{price}</p>
                    </div>
                  </div>
                </div>
              );
            }
          ) ?? <BeatLoader />}
        </Carousel>

        {/* <div className="secIntroSub">
          <div className="secTitleSub">Accomodation</div>
        </div>
        <Carousel responsive={responsive}>
          {accomodations.map(
            ({ title, id_stay, ticket_name, slug, description, image }) => {
              return (
                <div key={id_stay} className="attraction">
                  <Link to={`/accomodation/${slug}`}>
                    <div className="att-image">
                      <img src={image[0].image_path} alt="Image Title" />
                      <div className="overlayInfo">
                        <h3>{title}</h3>

                      </div>
                    </div>
                  </Link>
                  <div className="destFooter">
                    <div className="destText flex">
                      <Link to={`/accomodation/${slug}`}>
                        <h6>{title}</h6>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </Carousel> */}
      </section>

      <Footer />
    </div>
  );
};

export default Ticket;
