import React, { useState, useRef, useEffect } from "react";
import "./register.css";
// import kalselLogo from "../../assets/kalsel-logo.png";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import AuthService from "../../services/authService";
import { BeatLoader } from "react-spinners";

const Register = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [message, setMessage] = useState("");
  const [notification, setNotification] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    if (password !== password_confirmation) {
      setMessage("Password tidak sama, tolong cek kembali");
      return;
    }

    try {
      await AuthService.register(
        name,
        email,
        phone,
        password,
        password_confirmation
      );
      // navigate("/login");

      setNotification("Tolong cek email anda untuk verifikasi.");
      setMessage("");
      setSuccess(true);

      setTimeout(() => {
        navigate("/login");
      }, 10000); // Adjust the delay as needed
    } catch (error) {
      // console.log("errorrrrrr");
      // console.error(error.response.data.email[0]);
      setMessage(error.response.data.email[0]);
      setSuccess(false);
      setIsLoading(false); // Stop loading
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <form onSubmit={handleRegister}>
        <section className="register section">
          <div className="registerCard container">
            <div className="cardContent grid">
              <div className="cardText">
                <h3>Register</h3>
                <p>Halaman pendaftaran akun Kalselpark</p>
              </div>

              <div className="cardRegister grid">
                <div className="nameDiv">
                  <label htmlFor="name">Name</label>
                  <input
                    placeholder="Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="emailDiv">
                  <label htmlFor="email">Email</label>
                  <input
                    placeholder="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="emailDiv">
                  <label htmlFor="phone">No. Handphone</label>
                  <input
                    placeholder="Phone"
                    type="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                <div className="passwordDiv">
                  <label htmlFor="password">Password</label>
                  <input
                    placeholder="Password"
                    // type="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="passwordDiv">
                  <label htmlFor="password">Password Confirmation</label>
                  <input
                    placeholder="Password Confirmation"
                    // type="password"
                    type={showPassword ? "text" : "password"}
                    value={password_confirmation}
                    onChange={(e) => setPassword_confirmation(e.target.value)}
                  />
                  <button
                    type="button"
                    className="togglePasswordButton"
                    onClick={togglePasswordVisibility}
                    style={{ fontSize: "14px", color: "#7a7a7a" }}
                  >
                    {showPassword ? "Hide Password" : "Show Password"}
                  </button>
                </div>
              </div>
              <button type="submit" className="btn" disabled={isLoading}>
                {isLoading ? <BeatLoader /> : "Register"}
                {/* Register */}
              </button>
              <p>
                Sudah Punya Akun? Masuk{" "}
                <a
                  style={{ color: "green", fontWeight: "bold" }}
                  onClick={() => navigate("/login")}
                >
                  disini
                </a>{" "}
              </p>
              {message && <div className="error">{message}</div>}
              {notification && (
                <div className="notification">{notification}</div>
              )}
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default Register;
