import React from "react";
// import contact from "../../assets/contact-us.png";
import { useNavigate } from "react-router-dom";

const NeedHelp = () => {
  const navigate = useNavigate();
  const whatsappNumber = "+6282154746793";
  const whatsappLink = `https://wa.me/${whatsappNumber}`;
  return (
    <>
      <div>
        <span className="need-info">
          <a href={whatsappLink}>Butuh bantuan?</a>{" "}
        </span>
      </div>
    </>
  );
};

export default NeedHelp;
