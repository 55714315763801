import "./App.css";
// import { Fragment } from "react";
import React, { useState, createContext } from "react";
// import { fetchTickets } from "./services/ticketService";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing/Landing";
import Login from "../src/pages/Login/Login";
import Register from "./pages/Register/Register";
import DetailPackage from "./pages/DetailPackage/DetailPackage";
import Summary from "./pages/Summary/Summary";
import Profile from "./pages/Profile/Profile";
import Attraction from "./pages/Attraction/Attraction";
import DetailAttraction from "./pages/DetailAttraction/DetailAttraction";
import Ticket from "./pages/Ticket/Ticket";
import VATicket from "./pages/VATicket/VATicket";
import DetailActivity from "./pages/DetailActivity/DetailActivity";
import HutanPage from "./pages/HutanPage/HutanPage";
import SungaiPage from "./pages/SungaiPage/SungaiPage";
import BudayaPage from "./pages/BudayaPage/BudayaPage";
import HistoricalPage from "./pages/HistoricalPage/HistoricalPage";
import MountainHome from "./pages/MountainHome/MountainHome.jsx";
import GunungPage from "./pages/GunungPage/GunungPage";
import Xfactor from "./pages/XfactorPage/Xfactor";
import Merchandise from "./pages/Merchandise/Merchandise";
import MerchandiseDetail from "./pages/MerchandiseDetail/MerchandiseDetail";
import MountainGlamping from "./pages/MountainGlamping/MountainGlamping";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/PublicRoute/PublicRoute";
import Restaurants from "./pages/Restaurants/Restaurants";
import DetailRestaurant from "./pages/DetailRestaurant/DetailRestaurant";
import Maps from "./pages/Maps/Maps";
import Events from "./pages/Events/Events";
import TicketDetail from "./pages/TicketDetail/TicketDetail.jsx";
import TicketDetailPayment from "./pages/TicketDetailPayment/TicketDetailPayment.jsx";
import TicketPaymentVa from "./pages/TicketPaymentVa/TicketPaymentVa.jsx";
import VAPage from "./pages/VAPage/VAPage";
import { Provider } from "react-redux";
import { store } from "./store";
import GlampingDetail from "./pages/GlampingDetail/GlampingDetail";
import GlampingSummary from "./pages/GlampingSummary/GlampingSummary";
import GlampingSummaryPayment from "./pages/GlampingSummaryPayment/GlampingSummaryPayment";
import { ChakraProvider } from "@chakra-ui/react";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetail from "./pages/BlogDetail/BlogDetail";
import MapsDetail from "./pages/MapsDetail/MapsDetail";
import EventDetail from "./pages/EventDetail/EventDetail";
import ComingSoon from "./pages/ComingSoon/ComingSoon";
import Package from "./pages/Package/Package";
import PackageDetail from "./pages/PackageDetails/PackageDetail";
import PackageSummary from "./pages/PackageSummary/PackageSummary";
import PackagePayment from "./pages/PackagePayment/PackagePayment.jsx";
import EventSummary from "./pages/EventSummary/EventSummary";
import EventSummaryBook from "./pages/EventSummaryBook/EventSummaryBook.jsx";
import Resort from "./pages/Resort/Resort.jsx";

export const AppContext = createContext();
const App = () => {
  const [attraction, setAttraction] = useState([]);
  const [packages, setPackage] = useState([]);
  const [jumlahTiket, setJumlahTiket] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [method, setMethod] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bookingNumber, setBookingNumber] = useState(null);
  const [selectedBank, setSelectedBank] = useState([]);
  const [ticketQr, setTicketQr] = useState(null);
  const [ticketQrDetail, setTicketQrDetail] = useState([]);
  const [qris, setQris] = useState(null);
  const [language, setLanguage] = useState(true);

  return (
    <ChakraProvider>
      <Provider store={store}>
        <Router>
          <AppContext.Provider
            value={{
              language,
              setLanguage,
              attraction,
              setAttraction,
              jumlahTiket,
              setJumlahTiket,
              selectedDate,
              setSelectedDate,
              totalCost,
              setTotalCost,
              name,
              setName,
              email,
              setEmail,
              phone,
              setPhone,
              bookingNumber,
              setBookingNumber,
              selectedBank,
              setSelectedBank,
              ticketQr,
              setTicketQr,
              ticketQrDetail,
              setTicketQrDetail,
              method,
              setMethod,
              qris,
              setQris,
            }}
          >
            <Routes>
              <Route path="/" element={<ComingSoon />} />
              {/* <Route path="/" element={<Landing />} /> */}
              <Route
                path="/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="/register"
                element={
                  <PublicRoute>
                    <Register />
                  </PublicRoute>
                }
              />
              <Route path="/events" element={<Events />} /> {/* new route */}
              <Route
                path="/events/:slug/payment/:bookingNumber/pay"
                element={
                  <PrivateRoute>
                    <VAPage />
                  </PrivateRoute>
                }
              />
              <Route
                path="/events/:slug"
                element={<EventDetail />}
              />{" "}
              {/* new route */}
              <Route
                path="/events/:slug/payment/:bookingNumber"
                element={<EventSummaryBook />}
              />{" "}
              {/* new route */}
              <Route path="/events/:slug/payment/" element={<EventSummary />} /> 
              <Route path="/events/:slug" element={<EventDetail />} />{" "}
              {/* new route */}
              <Route path="/detailPackage" element={<DetailPackage />} />
              <Route path="/summary" element={<Summary />} />
              {/* <Route path="/va-payment" element={<VA />} /> */}
              <Route path="/va-payment-ticket" element={<VATicket />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                }
              />
              <Route path="/resort" element={<Resort />} />
              <Route path="/attraction" 
              element={<Ticket attraction={attraction} />}
              />
              {/* <Route
                path="/DetailAttraction/Theater-Geopark-Meratus"
                element={<DetailAttraction />}
              /> */}
              <Route
                path="/ticket-attraction/"
                element={<Ticket attraction={attraction} />}
              />
              <Route
                path="/ticket-attraction/:slug"
                element={<TicketDetail />}
              />
              <Route
                path="/ticket-attraction/:slug/payment"
                element={
                  <PrivateRoute>
                    <TicketDetailPayment attraction={attraction} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket-attraction/:slug/payment/:bookingNumber"
                element={
                  <PrivateRoute>
                    <TicketPaymentVa attraction={attraction} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ticket-attraction/:slug/payment/:bookingNumber/pay"
                element={
                  <PrivateRoute>
                    <VAPage />
                  </PrivateRoute>
                }
              />
              {/* <Route path="/ticketpayment" element={<TicketPayment />} /> */}
              <Route path="/detailactivity" element={<DetailActivity />} />
              <Route path="/forest-page" element={<HutanPage />} />
              <Route path="/river-page" element={<SungaiPage />} />
              <Route path="/culture-page" element={<BudayaPage />} />
              <Route path="/historical-page" element={<HistoricalPage />} />
              <Route
                path="/kalselpark-mountainview"
                element={<MountainHome />}
              />
              <Route
                path="/kalselpark-mountainview/glamping"
                element={<MountainGlamping />}
              />
              <Route path="/accomodation/:slug" element={<GlampingDetail />} />
              <Route
                path="/accomodation/:slug/booker-information"
                element={
                  <PrivateRoute>
                    <GlampingSummary />
                  </PrivateRoute>
                }
              />
              {/* navigate(`/accomodation/${roomType}/summary/payment/`); */}
              <Route
                path="/accomodation/:slug/booker-information/:bookingNumber"
                element={
                  <PrivateRoute>
                    <GlampingSummaryPayment />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accomodation/:slug/booker-information/:bookingNumber/payment"
                element={
                  <PrivateRoute>
                    <VAPage />
                  </PrivateRoute>
                }
              />
              <Route path="/mountain-page" element={<GunungPage />} />
              <Route path="/xfactor-page" element={<Xfactor />} />
              <Route path="/merchandise" element={<Merchandise />} />
              <Route
                path="/merchandise/:slug"
                element={<MerchandiseDetail />}
              />
              <Route path="/restaurants" element={<Restaurants />} />
              <Route path="/restaurants/:slug" element={<DetailRestaurant />} />
              <Route path="/maps" element={<Maps />} />
              <Route path="/maps/:slug" element={<MapsDetail />} />
              <Route path="/news" element={<Blogs />} />
              <Route path="/news/:slug" element={<BlogDetail />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="/package" element={<Package />} />
              <Route path="/package-detail/:slug" element={<PackageDetail />} />
              <Route
                path="/package/:slug/booker-information"
                element={
                  <PrivateRoute>
                    <PackageSummary />
                  </PrivateRoute>
                }
              />
              <Route
                path="/package-payment/:slug/payment/:bookingNumber"
                element={
                  <PrivateRoute>
                    <PackagePayment packages={packages} />
                  </PrivateRoute>
                }
              />
            </Routes>
          </AppContext.Provider>
        </Router>
      </Provider>
    </ChakraProvider>
  );
};

export default App;
