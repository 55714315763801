import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { BsArrowRightShort } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./maps.css";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { getMaps } from "../../services/mapsService";
import Carousel from "react-multi-carousel";

function Maps() {
  const [maps, setMaps] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const maps = await getMaps();

      setMaps(maps.data.data.mapss);
    };
    fetchData();
  }, []);

  //   console.log("maps");
  //   console.log(maps);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg">
        <section>
          <div className="text-center">
            <h1 className="title_page">Kalsel Park Maps</h1>
          </div>
          <div className="container">
            <Carousel responsive={responsive}>
              {maps.map((map) => (
                <div key={map.id_maps} className="attraction">
                  <Link to={`/maps/${map.show.slug}`}>
                    <div className="att-image">
                      <img src={map.image[0].image_path} alt="Image Title" />
                      <div className="overlayInfo">
                        <h3>{map.show.title}</h3>

                        <BsArrowRightShort className="icon" />
                      </div>
                    </div>
                  </Link>
                  <div className="destFooter">
                    <div className="destText flex">
                      <Link to={`/maps/${map.show.slug}`}>
                        <h6>{map.show.title}</h6>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Maps;
