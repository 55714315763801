import React, { useEffect, useState, useContext } from "react";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import img1 from "../../assets/news1.jpeg";
import img2 from "../../assets/news2.jpeg";
import { useNavigate } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import "./blogs.css";
import Pagination from "../../components/Pagination/Pagination";
import { fetchNews } from "../../services/newsService";
import { AppContext } from "../../App";

const Blogs = () => {
  const navigate = useNavigate();
  const { language } = useContext(AppContext);
  const [Posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchNews(4, currentPage)
      .then((response) => {
        setPosts(response.data.newss);
        setTotalPages(response.data.newsRecap.totalPage); // Assuming the API returns totalPages
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  }, [currentPage]);

  // console.log("totalPages");
  // console.log(totalPages);
  const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    return (
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            disabled={currentPage === index + 1}
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    );
  };
  // console.log("Posts");
  // console.log(Posts);
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="bgBlog">
        <div className="blog container section">
          <div className="secContainer">
            <div className="secIntro">
              <h2 className="secTitle" style={{ fontWeight: "600" }}>
                Kalsel Park News
              </h2>
              {language ? (
                <p>Berita dan event tentang Kalselpark</p>
              ) : (
                <p>News about Kalselpark</p>
              )}
            </div>

            <div className="mainContainer grid">
              {Posts.map(
                ({
                  id_news,
                  title,
                  title_en,
                  description,
                  description_en,
                  show,
                  slug,
                }) => {
                  return (
                    <div key={id_news} className="singlePost grid">
                      <div className="imgDiv">
                        <img src={show.image} alt={title} />
                      </div>
                      <div className="postDetails">
                        {language ? <h3>{title}</h3> : <h3>{title_en}</h3>}

                        {language ? (
                          <p className="p-justify">
                            {description
                              .replace(/(<([^>]+)>)/gi, "")
                              .slice(0, 100) + "..."}
                          </p>
                        ) : (
                          <p className="p-justify">
                            {description_en
                              .replace(/(<([^>]+)>)/gi, "")
                              .slice(0, 100) + "..."}
                          </p>
                        )}
                      </div>
                      <a
                        onClick={() => {
                          navigate(`/news/${slug}`);
                        }}
                        className="flex"
                      >
                        {language ? "Baca selanjutnya..." : "Read more..."}
                        <BsArrowRightShort className="icon" />
                      </a>
                    </div>
                  );
                }
              )}

              {/* <Pagination
                ticketUser={ticketUser.length}
                ticketPerPage={ticketPerPage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              /> */}
            </div>
          </div>
        </div>
        <Pagination
          className="pagination"
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </section>
      <Footer />
    </>
  );
};

export default Blogs;
